import { useState } from "react"
import {
    FormLabel,
    Stack,
    InputGroup,
    Input,
    Button,
    InputRightElement
} from '@chakra-ui/react';
import axios from "axios";
import { useSignIn } from "react-auth-kit";
import Logo from '../../assets/soulog_white_extended_logo.png';
import ScreenAlert from '../../components/Alert/Alert.component';
import { Link, useNavigate } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faEye, faEyeSlash, faLock } from "@fortawesome/free-solid-svg-icons";

export default function Login() {
    const [formData, setFormData] = useState({
        email: '',
        password: ''
    })

    const [showPassword, setShowPassword] = useState(false)

    const [error, setError] = useState('')
    const [info, setInfo] = useState('')
    const [loading, setLoading] = useState(false)

    const navigate = useNavigate()
    const signIn = useSignIn()

    async function handleSubmit(e) {
        e.preventDefault()
        setInfo(null)

        if (!formData.email || !formData.password) {
            return setInfo("Preencha os campos necessários para efetuar login")
        }

        const data = { email: formData.email, password: formData.password }

        const response = await axios.post('/api/user/login', data)

        try {
            setLoading(true)

            signIn({
                token: response.data.token,
                expiresIn: 259200,
                tokenType: "Bearer",
                authState: response.data.user
            })

            navigate("/")
        } catch (err) {
            setError(err.response?.data.message)
        }

        setLoading(false)
    }

    return (
        <section className="bg-stone-100 w-full h-screen flex flex-col justify-center items-center">
            <div className="px-6">
                {error && <ScreenAlert status="error" message={error} />}
                {info && <ScreenAlert status="info" message={info} />}
            </div>

            {/* Caixa de Login */}
            <div className="bg-white rounded-lg drop-shadow-lg md:w-[410px] md:h-[550px] w-[345px] h-[550px] py-10 px-10">
                <form className="flex flex-col justify-between items-center w-full h-full" onSubmit={handleSubmit}>
                    <figure className="w-34 h-24">
                        <img src={Logo} alt="Logo - SouLog Envios" />
                    </figure>

                    <Stack spacing={4}>
                        <InputGroup className="flex flex-col">
                            <FormLabel>
                                <FontAwesomeIcon icon={faEnvelope} className="mr-2" />
                                Email
                            </FormLabel>

                            <Input
                                type="email"
                                placeholder="Digite seu email"
                                onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                                value={formData.email}
                                borderColor="#808080"
                                width="270px"
                            />
                        </InputGroup>

                        <InputGroup className="flex flex-col">
                            <FormLabel>
                                <FontAwesomeIcon icon={faLock} className="mr-2" />
                                Senha
                            </FormLabel>
                            <Input
                                type={showPassword ? 'text' : 'password'}
                                pr="4.5rem"
                                placeholder="Digite sua senha"
                                onChange={(e) => setFormData({ ...formData, password: e.target.value })}
                                value={formData.password}
                                borderColor="#808080"
                                width="270px"
                            />

                            <InputRightElement width="2.5rem" height="6.4rem">
                                <button
                                    type="button"
                                    onClick={() => setShowPassword(prevState => !prevState)}
                                >
                                    <FontAwesomeIcon icon={showPassword ? faEye : faEyeSlash} />
                                </button>
                            </InputRightElement>
                        </InputGroup>
                    </Stack>

                    <Button
                        type="submit"
                        as='button'
                        transition='all 0.2s cubic-bezier(.08,.52,.52,1)'
                        width='270px'
                        borderRadius='8px'
                        fontSize='16px'
                        fontWeight='semibold'
                        bg='#607ecf'
                        color='#fff'
                        _hover={{ bg: '#7595ec', color: '#FFF' }}
                        disabled={loading}
                    >
                        Login
                    </Button>
                </form>
            </div>
        </section>
    )
}