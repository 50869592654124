import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function Card({ panel, cardType, title, icon, margin }) {
    const categoryView = {
        card: `flex flex-row lg:flex-col items-center rounded-lg px-8 
        bg-white drop-shadow-lg w-[90vw] h-[50px] md:w-[190px] md:h-[190px]
        hover:-translate-y-2 transition-all duration-300 ease-in-out ${margin} cursor-pointer`,
        icon: `text-xl lg:text-6xl text-soulog`
    };

    const panelView = {
        card: `flex flex-col justify-center items-center rounded-lg
        bg-white drop-shadow-lg w-[160px] h-[160px] md:w-[190px] md:h-[190px]
        hover:-translate-y-2 transition-all duration-300 ease-in-out ${margin} cursor-pointer`,
        icon: `text-5xl text-soulog`
    };

    return (
        <div className={cardType === "panelView" ? panelView.card : categoryView.card}>
            <figure className="flex items-center py-4">
                {panel ? (
                    <FontAwesomeIcon icon={icon} className={cardType === "panelView" ? panelView.icon : categoryView.icon} />
                ) : (
                    <img src={'https://apifaq.soulogenvios.com.br/static/' + icon} className="h-8 md:h-24" alt="ICONE"/>
                )}
            </figure>

            <h3 className="font-semibold text-md py-2 px-5 text-center overflow-hidden">{title}</h3>
        </div>
    )
}