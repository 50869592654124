import { faClose, faInfoCircle, faTicket } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import Menu from "./Menu";

export default function PageLayout({ children }) {
    const [open, setOpen] = useState(false)

    const handleInfo = () => {
        setOpen(prevState => !prevState)
    }

    return (
        <>
            <Menu />

            {children}

            <div className="fixed flex flex-col bottom-6 right-5">
                {open ? (
                    <div className="flex flex-col my-4 gap-y-4">
                        <a href="http://wa.me/551143182012" target="_blank" rel="noreferrer">
                            <span
                                className="flex items-center justify-center border-solid border-2 border-[#34af23] hover:border-dotted 
                            h-[55px] w-[55px] bg-[#34af23] hover:bg-gray-200 text-white hover:text-[#34af23] rounded-full cursor-pointer
                            transition duration-300 ease-in-out"
                            >
                                <FontAwesomeIcon icon="fa-brands fa-whatsapp" className="text-xl" />
                            </span>
                        </a>

                        {/* <a href="https://soulog.movidesk.com/" target="_blank" rel="noreferrer">
                            <span
                                className="flex items-center justify-center border-solid border-2 border-blue-500 hover:border-dotted 
                            h-[55px] w-[55px] bg-blue-500 hover:bg-gray-200 text-white hover:text-blue-500 rounded-full cursor-pointer
                            transition duration-300 ease-in-out"
                            >
                                <FontAwesomeIcon icon={faTicket} className="text-xl" />
                            </span>
                        </a> */}
                    </div>
                ) : null}

                {open ? (
                    <span className="flex items-center justify-center bg-red-500 drop-shadow-xl h-[55px] w-[55px] rounded-full cursor-pointer" onClick={handleInfo}>
                        <FontAwesomeIcon icon={faClose} className="text-white" />
                    </span>
                ) : (
                    <span className="flex items-center justify-center border-solid border-2 border-soulog hover:border-dotted
                    bg-soulog hover:bg-gray-200 text-white hover:text-soulog drop-shadow-xl h-[55px] w-[55px] rounded-full cursor-pointer" onClick={handleInfo}>
                        <FontAwesomeIcon icon={faInfoCircle} className="text-xl" />
                    </span>
                )}
            </div>


            <footer className="p-4 md:px-32 md:py-8">
                <hr className="my-6 sm:mx-auto border-gray-500" />

                <span class="block text-sm text-gray-500 sm:text-center">© {new Date().getFullYear()} <a href="https://envios.soulog.com.br/" target="_blank" rel="noreferrer" class="hover:underline">SouLog Envios</a>. Todos direitos reservados.
                </span>
            </footer>

        </>
    )
}